import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../layouts/Default';
import BlogRoll from '../../components/BlogRoll/BlogRoll';
import Section from '../../components/Section';
import BlogSectionTabs from '../../components/BlogSectionTabs';

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

export default function BlogIndexPage({
  data: {
    site: {
      siteMetadata: { title },
    },
  },
}) {
  const classes = useStyles();
  return (
    <Layout>
      <Helmet title={`Blog | ${title}`} />
      <Section className={classes.section}>
        <BlogSectionTabs selectedTab="blog" />
        <br />
        <BlogRoll />
      </Section>
    </Layout>
  );
}

BlogIndexPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
};

export const blogPageQuery = graphql`
  query BlogQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
