import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from '../PreviewCompatibleImage';

const useStyles = makeStyles((theme) => ({
  grid: {
    [theme.breakpoints.down('md')]: {
      gap: '30px 24px',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: '24px',
    },
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '50px 24px',
  },
  gridItem: {
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
    },
    display: 'block',
    cursor: 'pointer',
    color: 'inherit',
  },
}));

function BlogRoll({ data }) {
  const classes = useStyles();
  const { edges: posts } = data.allMdx;
  return (
    <div className={classes.grid}>
      {posts
        && posts.map(({ node: post }) => (
          <Link
            className={classes.gridItem}
            to={post.fields.slug}
            key={post.id}
          >
            <article>
                {post.frontmatter.featuredimage ? (
                  <header>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: post.frontmatter.featuredimage,
                        alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                      }}
                    />
                  </header>
                ) : null}
                <Box fontSize={14} mt={2} mb={1}>
                  {post.frontmatter.author} {' | '} {post.frontmatter.date}
                </Box>
                <Box fontWeight="fontWeightBold" fontSize={22} mb={1}>
                  {post.frontmatter.title}
                </Box>
                <Box fontWeight={500} fontSize={18}>
                  {post.frontmatter.description}
                </Box>
            </article>
          </Link>
        ))}
    </div>
  );
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`query BlogRollQuery {
  allMdx(
    sort: {order: DESC, fields: [frontmatter___date]}
    filter: {frontmatter: {templateKey: {eq: "blog-post"}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          templateKey
          date(formatString: "MMMM DD, YYYY")
          author
          featuredpost
          featuredimage {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
